import {css, cx} from '@styled-system/css';
import {icon} from '@styled-system/recipes';
import {Loader2} from 'lucide-react';
import {ComponentProps} from '@styled-system/types';

type SpinnerProps = ComponentProps<typeof Loader2>

const Spinner = (props: SpinnerProps) => {
  return (
    <Loader2 className={cx(icon(), css({ animation: 'spin' }))} {...props} />
  )
}

export default Spinner;
