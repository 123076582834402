import * as React from 'react'
import {Slot} from '@radix-ui/react-slot'
import {type HTMLStyledProps, styled} from '@styled-system/jsx'
import {button} from '@styled-system/recipes'
import Spinner from '~/components/ui/Spinner';

const BaseButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & { asChild?: boolean; children?: React.ReactNode, isLoading?: boolean }
>(({ asChild = false, isLoading = false, children, ...props }, ref) => {
  if (asChild) {
    return <Slot ref={ref} {...props}>
      {children}
    </Slot>
  } else {
    if (isLoading) {
      props.disabled = true;
    }
    return <button ref={ref} {...props}>
      <>
        {isLoading && <Spinner/>}
        {children}
      </>
    </button>
  }
})
BaseButton.displayName = 'Button'

export const Button = styled(BaseButton, button)
export type ButtonProps = HTMLStyledProps<typeof Button>
